import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { Parallax } from 'react-scroll-parallax'
import Img from 'gatsby-image'
import {
  Row,
  Col,
  Gap,
  Text,
  Title,
  Section,
  Button,
  TechnologyTop,
  TechnologyTopVideo,
  Link,
  SEO,
} from '../components'
import { up, addResponsivity } from '../lib/styles'

const CONTENT_WIDTH = '1012px'

const TextContent = ({ children }) => (
  <Row justifyContent="center">
    <Col
      maxWidth={CONTENT_WIDTH}
      mobileMaxWidth="600px"
      bp="tablet"
      alignItems="center"
    >
      <Text textAlign="justify" mobileTextAlign="left">{children}</Text>
    </Col>
  </Row>
)

const ContentImage = ({ ...props }) => (
  <Section.Simple background="white">
    <Col width="100%" maxWidth={CONTENT_WIDTH} margin="auto">
      <Img {...props} />
    </Col>
  </Section.Simple>
)

const TopTitle = styled(Title.Thin).attrs({
  innerAs: 'h1',
})`
  ${({ theme: { colors } }) => css`
    color: ${colors.textWhite};
    position: absolute;
    bottom: 30px;
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
    ${up('tablet')} {
      bottom: 60px;
    }
  `}
`

const HybridTitle = styled(Title.Thin).attrs({})`
  text-transform: uppercase;
  text-align: center;
  margin: 80px auto 40px auto;
`

const GapM = () => <Gap gap="40px" mobileGap="40px" bp="tablet" />
const GapL = () => <Gap gap="80px" mobileGap="40px" bp="tablet" />

class Hybrid extends React.Component {
  render() {
    const {
      location: { pathname },
      data: {
        topImage,
        topMobileImage,
        battery1Image,
        battery2Image,
        battery3Image,
        flightRangeImage,
        flightRangeMobileImage,
        safImage,
        map1Image,
        map2Image,
        map3Image,
        energyImage,
      },
    } = this.props

    return (
      <>
        <SEO
          title="Hybrid | Zuri"
          description="We are developing a modern private aircraft with vertical takeoff and landing. This will allow you to affordably travel from city to city at distances of up to 700 km apart."
          pathname={pathname}
        />
        <Gap.Top />

        <Col width="100%" position="relative">
          <Col
            width="100%"
            position="relative"
            maxWidth="1800px"
            margin="auto"
            hideBelow={800}
          >
            <Img fluid={topImage.image.fluid} />
          </Col>
          <Col width="100%" showBelow={800}>
            <Img fluid={topMobileImage.image.fluid} />
          </Col>

          <TopTitle>HYBRID POWER SYSTEM</TopTitle>
          <Gap gap="80px" />
        </Col>

        <Section>
          <GapM />
          <TextContent>
            <Text.Black>VTOL PROPULSION. </Text.Black>
            Since founding Zuri in 2017, we have always believed a hybrid
            powertrain is&nbsp;the best way to power VTOL aircraft. It allows us
            to provide a longer range while maintaining extremely low emissions.
          </TextContent>

          <GapL />
        </Section>

        <ContentImage fluid={energyImage.image.fluid} />

        <Section>
          <GapM />
          <TextContent>
            <Text.Black>5X MORE ENERGY. </Text.Black>
            Even the best batteries today have 5 times less energy than we can
            derive from a hybrid powertrain. And no, this isn’t going to change
            anytime soon. With the current progress in battery specific energy,
            it will still be 50-60 years before batteries catch up. The figure
            of 1360 Wh/kg shown above corresponds to a 225 kg turbogenerator
            producing 600&nbsp;kWh in 2 hours from 216 kg of fuel. That’s
            achievable TODAY.
          </TextContent>

          <HybridTitle>Usable Range</HybridTitle>
        </Section>

        <Section.Simple>
          <Row
            justifyContent="space-evenly"
            mobileJustifyContent="center"
            alignItems="flex-end"
            mobileAlignItems="center"
            width="100%"
            wrap="nowrap"
            margin="auto"
            mobileFlexDirection="column"
          >
            <Col width="100%">
              <Img fluid={map1Image.image.fluid} />
            </Col>
            <Gap gap="16px" mobileGap="8px" bp="tablet" />
            <Col width="100%">
              <Img fluid={map2Image.image.fluid} />
            </Col>
            <Gap gap="16px" mobileGap="8px" bp="tablet" />
            <Col width="100%">
              <Img fluid={map3Image.image.fluid} />
            </Col>
          </Row>
        </Section.Simple>

        <Section>
          <GapM />
          <TextContent>
            <Text.Black>USABLE RANGE. </Text.Black>
            While you may see some eVTOLs claiming a 240 km range, it's
            important to note that this is an absolute range. All aircraft
            (including current airplanes and helicopters) are required to
            maintain a mandatory reserve — either 30 minutes or 45 minutes
            (depending primarily on weather conditions). When the reserve is
            considered, an eVTOL has only a 100 km range with a 30-minute
            reserve. Thanks to its hybrid powertrain, Zuri has a range of almost
            900 km, making it capable of 700 km long flights even with a
            30-minute reserve.
          </TextContent>
          <HybridTitle>Sustainability</HybridTitle>
        </Section>

        <ContentImage fluid={safImage.image.fluid} />

        <Section>
          <GapM />
          <TextContent>
            <Text.Black>SAF. </Text.Black>
            How can a hybrid VTOL have lower emissions than an all-electric
            VTOL? There are three reasons.
            <br />
            1. The main one is SAF — Sustainable Aviation Fuel. Using SAF in a
            turbogenerator produces only 136 g CO₂ eq/kWh. Recharging an eVTOL
            from the electricity grid produces 275 g CO₂ eq/kWh, twice as much!
            <br />
            2. Zuri has at least three times smaller batteries, so the
            manufacturing and recycling costs and emissions are much smaller.
            <br />
            3. Since our flights are typically 10x longer and generally one
            flight means one battery life cycle, we can travel 10x longer
            distance before the battery needs replacing.
          </TextContent>

          <HybridTitle>
            Highly enhanced
            <br />
            Flight range
          </HybridTitle>
        </Section>

        <Section.Simple background="white">
          <Col
            width="100%"
            margin="auto"
            hideBelow="tablet"
            maxWidth={CONTENT_WIDTH}
          >
            <Img fluid={flightRangeImage.image.fluid} />
          </Col>
          <Col width="100%" showBelow="tablet">
            <Img fluid={flightRangeMobileImage.image.fluid} />
          </Col>
        </Section.Simple>

        <Section>
          <GapM />
          <TextContent>
            <Text.Black>FLIGHT RANGE. </Text.Black>
            Zuri integrates urban and regional air mobility by designing hybrid
            aircraft with optimized range capabilities, balancing VTOL for urban
            settings with extended flight capabilities for regional travel. Our
            approach includes efficient propulsion systems, aerodynamic design,
            and lightweight materials to maximize fuel efficiency and range.
            This allows for both short urban flights and longer regional trips,
            enhancing overall air mobility and connectivity.
          </TextContent>

          <HybridTitle>BATTERY</HybridTitle>
        </Section>

        <Row
          justifyContent="space-evenly"
          mobileJustifyContent="center"
          alignItems="flex-end"
          mobileAlignItems="center"
          width="100%"
          maxWidth="1435px"
          margin="auto"
          wrap="nowrap"
          mobileFlexDirection="column"
        >
          <Col basis="674px" mobileBasis={1} width="100%">
            <Img fluid={battery1Image.image.fluid} />

            <Title.UsagePlaneType white>eVTOL</Title.UsagePlaneType>
            <Gap gap="4px" />
            <Title.UsagePlaneType white>200-300 kWh</Title.UsagePlaneType>
          </Col>
          <Col
            basis="736px"
            mobileBasis={1}
            width="100%"
            margin="0"
            mobileMargin="40px auto 0"
          >
            <Img fluid={battery2Image.image.fluid} />
            <Title.UsagePlaneType white>Zuri</Title.UsagePlaneType>
            <Gap gap="4px" />
            <Title.UsagePlaneType white>60 kWh</Title.UsagePlaneType>
          </Col>
          <Col
            basis="510px"
            mobileBasis={1}
            mobileWidth="75%"
            margin="0"
            mobileMargin="40px auto 0"
          >
            <Img fluid={battery3Image.image.fluid} />
            <Col margin="0" mobileMargin="-40px 0 0 0" style={{ zIndex: 1 }}>
              <Title.UsagePlaneType white>Electric car</Title.UsagePlaneType>
              <Gap gap="4px" />
              <Title.UsagePlaneType white>75-100 kWh</Title.UsagePlaneType>
            </Col>
          </Col>
        </Row>
        <Gap gap="80px" mobileGap="40px" bp="tablet" />

        <Section>
          <GapM />

          <TextContent>
            <Text.Black>SAFE AND LIGHT BATTERY. </Text.Black>
            Hybrid power systems in aerospace applications outperform fully
            electric systems by integrating a generator set with electric
            propulsion. This reduces the need for a large battery, and the
            generator set can even recharge the battery during flight. Advanced
            thermal management in hybrids keeps batteries within ideal
            temperatures, enhancing longevity and reducing maintenance needs.
          </TextContent>
          <Gap gap="120px" mobileGap="80px" bp="tablet" />
        </Section>
      </>
    )
  }
}

export default Hybrid

export const pageQuery = graphql`
  fragment ContentImage on File {
    image: childImageSharp {
      fluid(maxWidth: 1150, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment MapImage on File {
    image: childImageSharp {
      fluid(maxWidth: 629, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    topImage: file(relativePath: { eq: "images/hybrid/hero.jpg" }) {
      ...FullHdImage
    }
    topMobileImage: file(
      relativePath: { eq: "images/hybrid/hero-mobile.jpg" }
    ) {
      image: childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    map1Image: file(relativePath: { eq: "images/hybrid/map1.jpg" }) {
      ...MapImage
    }
    map2Image: file(relativePath: { eq: "images/hybrid/map2.jpg" }) {
      ...MapImage
    }
    map3Image: file(relativePath: { eq: "images/hybrid/map3.jpg" }) {
      ...MapImage
    }

    battery1Image: file(relativePath: { eq: "images/hybrid/b1.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 674, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    battery2Image: file(relativePath: { eq: "images/hybrid/b2.v2.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 736, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    battery3Image: file(relativePath: { eq: "images/hybrid/b3.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 510, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    flightRangeImage: file(
      relativePath: { eq: "images/hybrid/flight-range.jpg" }
    ) {
      ...ContentImage
    }
    flightRangeMobileImage: file(
      relativePath: { eq: "images/hybrid/flight-range-mobile.jpg" }
    ) {
      image: childImageSharp {
        fluid(maxWidth: 790, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    safImage: file(relativePath: { eq: "images/hybrid/saf.jpg" }) {
      ...ContentImage
    }
    energyImage: file(relativePath: { eq: "images/hybrid/more-energy.jpg" }) {
      ...ContentImage
    }
  }
`
